import React from "react";
import Sidenav from "../../../../vendor/libs/sidenav";
import { NavMenuItemLink } from "../navigation-menu-items/NavMenuItemLink";

export const AgentAdminMenu = (props) => {
  let menuItems = [];

  if (props.permissions.indexOf("USER_VIEW") > -1) {
    menuItems.push(
      "admin/audit-log",
      "admin/users",
      "admin/permission-templates"
    );
  }

  if (props.permissions.indexOf("PROV_VIEW") > -1) {
    menuItems.push("admin/provider-groups");
  }

  if (props.permissions.indexOf("PROC_EDIT") > -1) {
    menuItems.push(
      "admin/bundles",
      "admin/bundle-templates",
      "admin/service-categories",
      "admin/service-bulk-update",
      "admin/bundle-components",
      "admin/practice-areas",
      "admin/pricing-components",
      "admin/roster-imports",
      "admin/xml-import",
      "admin/patient-offer-report"
    );
  }

  return (
    <Sidenav.Menu
      icon="fas fa-cog"
      linkText="Admin"
      active={props.isMenuActive("/admin")}
      open={props.isMenuOpen("/admin")}
    >
      {menuItems.map((menuItem) => {
        return (
          <NavMenuItemLink
            key={menuItem}
            name={menuItem}
            clearAllFilters={props.clearAllFilters}
          />
        );
      })}
    </Sidenav.Menu>
  );
};
