import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { UserContext } from "../../auth/FirebaseUserProvider";
import firebase from "firebase/app";
import "firebase/auth";
import SignInModal from "../../components/public/SignInModal";

const checkActive = (match, location) => {
  //some additional logic to verify you are in the home URI
  if (!location) return false;
  const { pathname } = location;
  return pathname === "/";
};

export default function LayoutNavbarPublic(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const user = useContext(UserContext);

  return (
    <>
      <Navbar
        bg="white"
        expand="lg"
        className="layout-navbar align-items-lg-center container-p-x py-3"
        style={{ zIndex: 50 }}
      >
        <div className="container flex-wrap">
          <Navbar.Brand
            as={NavLink}
            to="/"
            className="app-brand demo d-lg-none py-0"
            isActive={checkActive}
          >
            <img
              src={`/img/logo.png`}
              alt="Fair Market Health"
              className="d-none d-md-block app-brand-logo demo mr-3"
              style={{ height: "58px" }}
            />
            <img
              src={`/img/logo.png`}
              alt="Fair Market Health"
              className="d-sm-none app-brand-logo demo mr-3"
              style={{ height: "38px" }}
            />
          </Navbar.Brand>

          <Navbar.Toggle>
            <i className="navbar-toggler-icon"></i>
          </Navbar.Toggle>

          <Navbar.Collapse>
            {/* Divider */}
            <hr className="d-lg-none w-100 my-2" />

            <Nav className="align-items-lg-center">
              {/* <Nav.Link as={NavLink} to="/search" className="mr-2">
                Services
              </Nav.Link>
              <NavDropdown title="About Us" id="nav-dropdown">
                <NavDropdown.Item>
                  <Nav.Link
                    as={NavLink}
                    to="/about-fair-market-health"
                    className="mr-2"
                  >
                    About Us
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Nav.Link
                    as={NavLink}
                    to="/what-people-are-saying"
                    className="mr-2"
                  >
                    What People Are Saying
                  </Nav.Link>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="How It Works" id="nav-dropdown">
                <NavDropdown.Item eventKey="4.1">
                  <Nav.Link as={NavLink} to="/how-it-works" className="mr-2">
                    How It Works
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="4.2">
                  <Nav.Link as={NavLink} to="/how-you-save" className="mr-2">
                    How You Save
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="4.3">
                  <Nav.Link
                    as={NavLink}
                    to="fair-market-health-for-patients"
                    className="mr-2"
                  >
                    For Patients
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="4.4">
                  <Nav.Link
                    as={NavLink}
                    to="/fair-market-health-for-providers"
                    className="mr-2"
                  >
                    For Providers
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Nav.Link
                    as={NavLink}
                    to="/fair-market-health-for-brokers"
                    className="mr-2"
                  >
                    For Brokers
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Nav.Link
                    as={NavLink}
                    to="/fair-market-health-for-employers"
                    className="mr-2"
                  >
                    For Employers
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Nav.Link
                    as={NavLink}
                    to="/frequently-asked-questions"
                    className="mr-2"
                  >
                    Frequently Asked Questions
                  </Nav.Link>
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={NavLink} to="/financing" className="mr-2">
                Financing
              </Nav.Link>
              <Nav.Link as={NavLink} to="/careers" className="mr-2">
                Careers
              </Nav.Link>
            </Nav>

            <Nav className="align-items-lg-center ml-auto">
              <a
                href="tel:+13162028558"
                className="nav-link mr-lg-2 d-none d-lg-block"
              >
                (316) 202-8558
              </a>
              <Nav.Link
                as={NavLink}
                to="/search"
                className="demo-navbar-notifications mr-lg-2"
              >
                <i className="ion ion-ios-search navbar-icon align-middle"></i>
                <span className="d-lg-none align-middle">&nbsp; Search</span>
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/cart"
                className="demo-navbar-notifications mr-lg-2"
              >
                <i className="ion ion-md-cart navbar-icon align-middle"></i>
                <span className="d-lg-none align-middle">&nbsp; View Cart</span>
              </Nav.Link> */}

              {/* Divider */}
              {/* <div className="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-50 mr-3 ml-1">
                |
              </div> */}
              {user == null ? (
                <>
                  <Nav.Link
                    onClick={() => setModalOpen(true)}
                    className="mr-2 sign-in-link float-right"
                  >
                    Sign In
                  </Nav.Link>
                </>
              ) : (
                <>
                  <div className="nav-item d-none d-lg-block">
                    <Nav.Link as={NavLink} to="/my-account" className="mr-2">
                      My Account
                    </Nav.Link>
                  </div>

                  {/* <div className="nav-item d-sm-block d-lg-none">
                    <NavDropdown title="My Account" id="nav-dropdown">
                      <NavDropdown.Item eventKey="5.1">
                        <Nav.Link
                          as={NavLink}
                          to="/my-account"
                          className="mr-2"
                        >
                          My Profile
                        </Nav.Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item eventKey="5.2">
                        <Nav.Link as={NavLink} to="/my-family" className="mr-2">
                          Family Members
                        </Nav.Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item eventKey="5.3">
                        <Nav.Link
                          as={NavLink}
                          to="/my-procedures"
                          className="mr-2"
                        >
                          My Services
                        </Nav.Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item eventKey="5.4">
                        <Nav.Link
                          as={NavLink}
                          to="/my-recurring"
                          className="mr-2"
                        >
                          My Recurring Services
                        </Nav.Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item eventKey="5.5">
                        {" "}
                        <Nav.Link
                          as={NavLink}
                          to="/payment-history"
                          className="mr-2"
                        >
                          Payment History
                        </Nav.Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item eventKey="5.6">
                        {" "}
                        <Nav.Link
                          as={NavLink}
                          to="/payment-methods"
                          className="mr-2"
                        >
                          Payment Methods
                        </Nav.Link>{" "}
                      </NavDropdown.Item>
                      <NavDropdown.Item eventKey="5.7">
                        {" "}
                        <Nav.Link
                          as={NavLink}
                          to="/notifications"
                          className="mr-2"
                        >
                          Notifications
                        </Nav.Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item eventKey="5.8">
                        {" "}
                        <Nav.Link as={NavLink} to="/cart" className="mr-2">
                          View Cart
                        </Nav.Link>{" "}
                      </NavDropdown.Item>

                      <NavDropdown.Item eventKey="5.9">
                        <div
                          onClick={() => {
                            firebase.auth().signOut();
                          }}
                        >
                          Log Out
                        </div>
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div> */}
                </>
              )}
              {/* <a
                href="tel:+13162028558"
                className="nav-link mr-lg-2 d-lg-none"
                style={{ marginTop: "15px" }}
              >
                (316) 202-8558
              </a> */}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <SignInModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </>
  );
}

// LayoutNavbarPublic.propTypes = {
//   sidenavToggle: PropTypes.bool,
// };

// LayoutNavbarPublic.defaultProps = {
//   sidenavToggle: true,
// };

// export default connect((store) => ({
//   navbarBg: store.theme.navbarBg,
// }))(LayoutNavbarPublic);
