import React from "react";
import Sidenav from "../../../../vendor/libs/sidenav";
import { NavMenuItemLink } from "../navigation-menu-items/NavMenuItemLink";

export const ProviderReportsMenu = (props) => {
  const menutItems = ["reports/payout-report", "reports/survey-results-report"];
  return (
    <Sidenav.Menu
      icon="fas fa-clipboard"
      linkText="Reports"
      active={props.isMenuActive("/reports")}
      open={props.isMenuOpen("/reports")}
    >
      {menutItems.map((menuItem) => {
        return (
          <NavMenuItemLink
            key={menuItem}
            name={menuItem}
            clearAllFilters={props.clearAllFilters}
          />
        );
      })}
    </Sidenav.Menu>
  );
};
