import React from "react";
import { Link } from "react-router-dom";

export default function HeaderSidenav(props) {
  return (
    <div className="app-brand demo">
      <Link
        onClick={() => props.clearAllFilters()}
        to={props.rootDashboard}
        className="app-brand-text demo sidenav-text font-weight-normal ml-1 mt-1"
      >
        <img
          src={`/img/logo_inverse.png`}
          alt="Fair Market Health"
          className="app-brand-logo demo"
        />

        <img
          src={`/img/mark.png`}
          alt="Fair Market Health"
          className="app-brand-mark demo"
        />
      </Link>
      <a
        href="#toggle"
        className="layout-sidenav-toggle sidenav-link text-large ml-auto"
        onClick={props.toggleSidenav}
      >
        <i className="ion ion-md-menu align-middle"></i>
      </a>
    </div>
  );
}
