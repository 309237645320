import React, { Component } from "react";
import { connect } from "react-redux";

export default function LayoutFooter(props) {
  const d = new Date();

  function prevent(e) {
    e.preventDefault();
  }

  return (
    <nav className="layout-footer footer bg-dark">
      <div className="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
        <div className="pt-3">
          <span className="footer-text">
            © {d.getFullYear()} Fair Market Health
          </span>
        </div>
        <div>
          <a href="/terms-of-use" className="footer-link pt-3 ml-4">
            Terms of Service
          </a>
          <a href="/privacy-policy" className="footer-link pt-3 ml-4">
            Privacy Policy
          </a>
        </div>
      </div>
    </nav>
  );
}
