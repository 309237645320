export function parseIntRemoveCommas(value) {
  if (typeof value === "number") {
    return value;
  }

  return parseInt(value.replace(",", ""));
}

export function parseFloatRemoveCommas(value) {
  if (typeof value === "number") {
    return value;
  }

  return parseFloat(value.replace(",", ""));
}

export function FormatOrderText(content, patient_procedure_id) {
  content = content.replace(/<id>/gi, patient_procedure_id);
  return content;
}

export function roundTo2(value) {
  value = Math.round(value * 100) / 100;
  return parseFloat(value.toFixed(2));
}

export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export function getRandomString(length) {
  var randomChars = "BCDFGHJKLMNPQRTWXY34689";
  var result = "";
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
}

export function titleCase(string) {
  var sentence = string.toLowerCase().split(" ");
  for (var i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence.join(" ");
}

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export function formatMoney(value) {
  return formatter.format(value).replace(/\D00$/, "");
}

export function customProcedureName(procedure, provider_procedures) {
  return provider_procedures.length > 0 &&
    provider_procedures[0].custom_procedure_name
    ? provider_procedures[0].custom_procedure_name
    : procedure.procedure_name;
}

export function customProcedureDescription(procedure, provider_procedures) {
  return provider_procedures.length > 0 &&
    provider_procedures[0].custom_procedure_description &&
    provider_procedures[0].custom_procedure_description
      .replace(/<\/?[^>]+(>|$)/g, "")
      .replace(/\s/g, "") != ""
    ? provider_procedures[0].custom_procedure_description
    : procedure.description;
}

export function getPayoutDescription(payout_status) {
  switch (payout_status) {
    case "Error":
      return "This indicates there was a problem making the payment to the recipient's account";
    case "Paid":
      return "This indicates the recipient was successfully paid.  The payment may take up to 48 hours to settle to in the recipient's bank account.";
    case "Postponed":
      return "A payment will be postponed if 5 days have not passed from the date the order was created and the service was marked complete.  After 5 days have passed, postponed payments will be retried.";
    case "Cancelled":
      return "This indicates the payment was manually cancelled by a Fair Market Health agent.";
    case "Pending":
      return "A pending payment indicates that the service has not been marked Complete.";
    case "Scheduled":
      return "A scheduled payment indicates that the payment will be paid out in the next 24 hours.";
    default:
      return "";
  }
}

export function getFriendlyFirebaseErrorMessage(code) {
  switch (code) {
    case "auth/operation-not-allowed":
      return "Anonymous accounts are not enabled";
      break;
    case "auth/weak-password":
      return "Your password is too weak";
      break;
    case "auth/invalid-email":
      return "Your email is invalid";
      break;
    case "auth/email-already-in-use":
      return "That email is already registered. Try logging in.";
      break;
    case "auth/invalid-credential":
      return "Your email is invalid";
      break;

    case "auth/invalid-password":
      return "Incorrect password, please try again.";
      break;
    case "auth/user-not-found":
      return "That user doesn't exist. Try signing up.";
      break;
    case "auth/user-disabled":
      return "That user has been disabled.";
      break;
    case "auth/too-many-requests":
      return "Too many requests as that user.";
      break;

    default:
      return code;
  }
}

export function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
}
