import React from "react";
import Sidenav from "../../../../vendor/libs/sidenav";
import { NavMenuItemLink } from "../navigation-menu-items/NavMenuItemLink";
import { EmployerAdminMenu } from "./EmployerAdminMenu";

export default function EmployerToolsMenu(props) {
  const menuItems = [
    "employer/dashboard",
    "employer/health-plans",
    "employer/orders",
    "employer/payments",
    "employer/my-details",
  ];
  return (
    <>
      <Sidenav.Header className="small font-weight-semibold">
        EMPLOYER TOOLS
      </Sidenav.Header>

      {menuItems.map((menuItem) => {
        return (
          <NavMenuItemLink
            key={menuItem}
            name={menuItem}
            clearAllFilters={props.clearAllFilters}
          />
        );
      })}
      {props.permissions.indexOf("USER_VIEW") > -1 && (
        <EmployerAdminMenu
          clearAllFilters={props.clearAllFilters}
          isMenuOpen={props.isMenuOpen}
          isMenuActive={props.isMenuActive}
          permissions={props.permissions}
        />
      )}
    </>
  );
}
