import React from "react";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import Loader from "./shared/Loader";

// Layouts
import Layout2 from "./shared/layouts/Layout2";
import LayoutFMH from "./shared/layouts/LayoutFMH";
import LayoutFMHNoNav from "./shared/layouts/LayoutFMHNoNav";
import LayoutBlank from "./shared/layouts/LayoutBlank";

// Lazy load component
const lazy = (cb) =>
  loadable(() => pMinDelay(cb(), 200), { fallback: <Loader /> });

export const DefaultLayout = Layout2;

export const titleTemplate = "%s - Fair Market Health";

// ---
// Routes
//
// Note: By default all routes use { "exact": true }. To change this
// behaviour, pass "exact" option explicitly to the route object
if (window.location.href.indexOf("fmh.link") > -1) {
  window.location.href = window.location.href.replace(
    "fmh.link/",
    "fairmarkethealth.com/p/"
  );
}

export const defaultRoute = "/";
export const routes = [
  {
    path: "/",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/financing",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/checkin",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/about-fair-market-health",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/what-people-are-saying",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/how-it-works",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/careers",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/provider-email-verification",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/how-you-save",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/fair-market-health-for-patients",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/fair-market-health-for-providers",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/fair-market-health-for-brokers",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/fair-market-health-for-employers",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/frequently-asked-questions",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/terms-of-use",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/privacy-policy",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/create-provider-account",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/l/:slug",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMHNoNav,
  },
  {
    path: "/p/:slug",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMHNoNav,
  },
  {
    path: "/p/procedure-detail/:slug/:id",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMHNoNav,
  },
  {
    path: "/l/procedure-detail/:group_slug/:id",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMHNoNav,
  },
  {
    path: "/doctors",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/videos",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/search",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/procedure-detail/:id",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/procedure-detail/:slug/:id",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/book-now/:id",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutBlank,
  },
  {
    path: "/provider-detail/:id",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/cart",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/order-confirmation/:id",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
    protected: true,
  },
  {
    path: "/login",
    component: lazy(() => import("./components/public/Login")),
    layout: LayoutFMH,
  },
  {
    path: "/sign-in",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
    routeVariables: { showSignIn: true },
  },
  {
    path: "/reset-password",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
    routeVariables: { showReset: true },
  },
  {
    path: "/sign-out",
    component: lazy(() => import("./components/public/SignOut")),
    layout: LayoutFMH,
    routeVariables: { showSignIn: true },
  },
  {
    path: "/sign-up",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/employee-activation/:employer_id",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/employee-activation",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/provider-sign-up",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/provider-sign-up-complete",
    component: lazy(() => import("./components/public/Home")),
    protected: true,
  },
  {
    path: "/provider-onboard",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  {
    path: "/my-account",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
    protected: true,
  },
  {
    path: "/my-family",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
    protected: true,
  },
  {
    path: "/my-procedures",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
    protected: true,
  },
  {
    path: "/my-recurring",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
    protected: true,
  },
  {
    path: "/view-receipt/:id",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
    protected: true,
  },
  {
    path: "/payment-methods",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
    protected: true,
  },
  {
    path: "/payment-history",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
    protected: true,
  },
  {
    path: "/notifications",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
    protected: true,
  },
  {
    path: "/settings",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
    protected: true,
  },
  {
    path: "/dashboard",
    component: lazy(() => import("./components/dashboards/Dashboard")),
    protected: true,
  },
  {
    path: "/welcome",
    component: lazy(() => import("./components/welcome/WelcomeProvider")),
    protected: true,
  },
  {
    path: "/support",
    component: lazy(() => import("./components/dashboards/Support")),
    protected: true,
  },
  {
    path: "/feedback",
    component: lazy(() => import("./components/dashboards/Support")),
    protected: true,
  },
  // {
  //   path: "/3d",
  //   component: lazy(() => import("./components/dashboards/3d")),
  //   protected: true,
  // },
  {
    path: "/patients",
    component: lazy(() => import("./components/patients/Patients")),
    protected: true,
  },
  {
    path: "/patients/:id",
    component: lazy(() => import("./components/patients/Patient")),
    protected: true,
  },
  {
    path: "/admin/users",
    component: lazy(() => import("./components/users/Users")),
    protected: true,
  },
  {
    path: "/admin/bundles",
    component: lazy(() => import("./components/catalog/Bundles")),
    protected: true,
  },
  {
    path: "/admin/bundletemplates",
    component: lazy(() => import("./components/catalog/BundleTemplates")),
    protected: true,
  },
  {
    path: "/admin/permissiontemplates",
    component: lazy(() => import("./components/users/PermissionTemplates")),
    protected: true,
  },
  {
    path: "/orders",
    component: lazy(() => import("./components/orders/Orders")),
    protected: true,
  },
  {
    path: "/orders/:id",
    component: lazy(() => import("./components/orders/Order")),
    protected: true,
  },
  {
    path: "/catalog/procedures",
    component: lazy(() => import("./components/catalog/Procedures")),
    protected: true,
  },
  {
    path: "/admin/categories",
    component: lazy(() => import("./components/catalog/Categories")),
    protected: true,
  },
  {
    path: "/admin/service-bulk-update",
    component: lazy(() => import("./components/catalog/ServiceBulkUpdate")),
    protected: true,
  },
  {
    path: "/admin/bundle-components",
    component: lazy(() => import("./components/catalog/BundleComponents")),
    protected: true,
  },
  {
    path: "/admin/pricing-components",
    component: lazy(() => import("./components/catalog/PricingComponents")),
    protected: true,
  },
  {
    path: "/admin/practice-areas",
    component: lazy(() => import("./components/catalog/PracticeAreas")),
    protected: true,
  },
  {
    path: "/admin/roster-imports",
    component: lazy(() => import("./components/employers/RosterImports")),
    protected: true,
  },
  {
    path: "/admin/xml-import",
    component: lazy(() =>
      import("./components/reports/ProviderServicesImport")
    ),
    protected: true,
  },
  {
    path: "/admin/patient-offer-report",
    component: lazy(() => import("./components/reports/PatientOfferReport")),
    protected: true,
  },
  {
    path: "/audit-log",
    component: lazy(() => import("./components/audit-log/AuditLog")),
    protected: true,
  },
  {
    path: "/catalog/procedures/:id",
    component: lazy(() => import("./components/catalog/Procedure")),
    protected: true,
  },
  {
    path: "/providers",
    component: lazy(() => import("./components/providers/Providers")),
    protected: true,
  },
  {
    path: "/admin/providergroups",
    component: lazy(() => import("./components/providers/ProviderGroups")),
    protected: true,
  },
  {
    path: "/groupproviders",
    component: lazy(() => import("./components/providers/GroupProviders")),
    protected: true,
  },
  {
    path: "/groupprovideractivation",
    component: lazy(() =>
      import("./components/providers/GroupProviderActivation")
    ),
    protected: true,
  },

  {
    path: "/providers/:id",
    component: lazy(() => import("./components/providers/Provider")),
    protected: true,
  },
  {
    path: "/provider/dashboard",
    component: lazy(() => import("./components/provider/Dashboard")),
    protected: true,
  },
  {
    path: "/provider/procedures",
    component: lazy(() => import("./components/provider/MyProcedures")),
    protected: true,
  },
  {
    path: "/provider/orders",
    component: lazy(() => import("./components/provider/Orders")),
    protected: true,
  },
  {
    path: "/provider/orders/:id",
    component: lazy(() => import("./components/provider/Order")),
    protected: true,
  },
  {
    // Patients of the Provider
    path: "/provider/patients",
    component: lazy(() => import("./components/provider/Patients")),
    protected: true,
  },
  {
    // Patient of the Provider
    path: "/provider/:prov_id/patients/:id",
    component: lazy(() => import("./components/provider/PatientOrderHistory")),
    protected: true,
  },
  {
    path: "/provider/stripe/:id",
    component: lazy(() => import("./components/provider/StripeDashboard")),
    protected: true,
  },
  {
    path: "/provider/payouts",
    component: lazy(() => import("./components/provider/MyPayouts")),
    protected: true,
  },
  {
    path: "/provider/reviews",
    component: lazy(() => import("./components/provider/CustomerReviews")),
    protected: true,
  },
  {
    path: "/employers",
    component: lazy(() => import("./components/employers/Employers")),
    protected: true,
  },
  {
    path: "/employers/:id",
    component: lazy(() => import("./components/employers/Employer")),
    protected: true,
  },
  {
    path: "/employers/:id/plans/:employer_plan_id",
    component: lazy(() => import("./components/employers/EmployerPlan")),
    protected: true,
  },
  {
    path: "/reports/payoutreport",
    component: lazy(() => import("./components/reports/PayoutReport")),
    protected: true,
  },
  {
    path: "/reports/payoutescrowreport",
    component: lazy(() => import("./components/reports/PayoutEscrowReport")),
    protected: true,
  },
  {
    path: "/reports/searchreport",
    component: lazy(() => import("./components/reports/SearchReport")),
    protected: true,
  },
  {
    path: "/reports/missingnapreport",
    component: lazy(() => import("./components/reports/MissingNapReport")),
    protected: true,
  },
  {
    path: "/reports/surveyresultsreport",
    component: lazy(() => import("./components/reports/SurveyResultsReport")),
    protected: true,
  },
  {
    path: "/reports/patientsreport",
    component: lazy(() => import("./components/reports/PatientsReport")),
    protected: true,
  },
  {
    path: "/tasks",
    component: lazy(() => import("./components/Tasks")),
    protected: true,
  },
  {
    path: "/calendar",
    component: lazy(() => import("./components/Calendar")),
    protected: true,
  },
  {
    path: "/employer/dashboard",
    component: lazy(() => import("./components/employer/Dashboard")),
    protected: true,
  },
  {
    path: "/employer/dashboard/:id",
    component: lazy(() => import("./components/employer/Dashboard")),
    protected: true,
  },
  {
    path: "/employer/my-details",
    component: lazy(() => import("./components/employer/MyDetails")),
    protected: true,
  },
  {
    path: "/employer/employees",
    component: lazy(() => import("./components/employer/Employees")),
    protected: true,
  },
  {
    path: "/employer/plans",
    component: lazy(() => import("./components/employer/Plans")),
    protected: true,
  },
  {
    path: "/employer/payments",
    component: lazy(() => import("./components/employer/Payments")),
    protected: true,
  },
  {
    path: "/employer/orders",
    component: lazy(() => import("./components/employer/Orders")),
    protected: true,
  },
  {
    path: "/messages",
    component: lazy(() => import("./components/temp/Temp")),
    protected: true,
  },
  {
    path: "/reports",
    component: lazy(() => import("./components/temp/Reports")),
    protected: true,
  },
  {
    path: "/patients/patientfeedbacksurvey/:id",
    component: lazy(() => import("./components/public/Home")),
    protected: true,
    layout: LayoutFMH,
  },
  {
    path: "/create-provider-account",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  //  Bariatric Landing Page
  {
    path: "/bariatric",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  //  Small Business Landing Page
  {
    path: "/smallbiz",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
  //  Providers Special Offer Landing Page
  {
    path: "/ProV1",
    component: lazy(() => import("./components/public/Home")),
    layout: LayoutFMH,
  },
];
