import { menuItems } from "./NavMenuItems";
import React from "react";
import Sidenav from "../../../../vendor/libs/sidenav";

export const NavMenuItemLink = (props) => {
  let menuItem;

  const segments = props.name.split("/");

  if (segments.length === 1) {
    menuItem = menuItems[segments[0]];
  }

  if (segments.length > 1) {
    menuItem = menuItems[segments[0]][segments[1]];
  }

  if (menuItem) {
    return (
      <Sidenav.RouterLink
        onClick={() => props.clearAllFilters()}
        to={menuItem.path}
        icon={menuItem.icon ? menuItem.icon : ""}
      >
        {menuItem.name}
      </Sidenav.RouterLink>
    );
  }
};
