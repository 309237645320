import React from "react";
import Sidenav from "../../../../vendor/libs/sidenav";
import { AgentReportsMenu } from "./AgentReportsMenu";
import { AgentAdminMenu } from "./AgentAdminMenu";
import { NavMenuItemLink } from "../navigation-menu-items/NavMenuItemLink";

export default function AgentToolsMenu(props) {
  let menuItems = [];

  if (props.permissions.indexOf("ORD_VIEW") > -1) {
    menuItems.push("orders");
  }

  if (props.permissions.indexOf("PAT_VIEW") > -1) {
    menuItems.push("patients");
  }

  if (props.permissions.indexOf("PROV_VIEW") > -1) {
    menuItems.push("providers");
  }

  if (props.permissions.indexOf("EMP_VIEW") > -1) {
    menuItems.push("employers");
  }

  if (props.permissions.indexOf("PROC_VIEW") > -1) {
    menuItems.push("services");
  }
  return (
    <>
      <Sidenav.Header className="small font-weight-semibold">
        AGENT TOOLS
      </Sidenav.Header>
      {menuItems.map((menuItem) => {
        return (
          <NavMenuItemLink
            key={menuItem}
            name={menuItem}
            clearAllFilters={props.clearAllFilters}
          />
        );
      })}
      {/**  Reports */}
      {props.permissions.indexOf("REP_PAYOUT_VIEW") > -1 && (
        <AgentReportsMenu
          clearAllFilters={props.clearAllFilters}
          isMenuOpen={props.isMenuOpen}
          isMenuActive={props.isMenuActive}
        />
      )}
      {/**  Admin */}
      {(props.permissions.indexOf("USER_VIEW") > -1 ||
        props.permissions.indexOf("PROC_EDIT") > -1) && (
        <AgentAdminMenu
          clearAllFilters={props.clearAllFilters}
          isMenuOpen={props.isMenuOpen}
          isMenuActive={props.isMenuActive}
          permissions={props.permissions}
        />
      )}
    </>
  );
}
