import React, { useState, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Row, Col, Card, Form, Button, Modal } from "react-bootstrap";
import "../../vendor/styles/pages/authentication.scss";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import "../../vendor/styles/pages/authentication.scss";
import { getFriendlyFirebaseErrorMessage } from "../../utils/stringutils";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { useBrowserQuery } from "../../utils/routerutils";
import { clearAllFilters } from "../../utils/localStorageUtils";
import { useImperativeQuery } from "../../utils/gqlutils";

export default function SignInModal(props) {
  let querystring = useBrowserQuery();
  const [showPasswordRecovery, setShowPasswordRecovery] = useState(false);
  const [showRecoverySent, setShowRecoverySent] = useState(false);
  const [loginError, setLoginError] = useState("");
  let history = useHistory();
  const [sendReset] = useMutation(SEND_RESET);
  const [updateLastLogin] = useMutation(UPDATE_LAST_LOGIN);

  useEffect(() => {
    setShowPasswordRecovery(false);
    setShowRecoverySent(false);
  }, [props.modalOpen]);

  const handleSubmit = async (values, setSubmitting) => {
    setSubmitting(true);
    clearAllFilters();
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

    try {
      let signInResult = await firebase
        .auth()
        .signInWithEmailAndPassword(values.email, values.password);

      let updateLastLoginResult = updateLastLogin({
        variables: { email: values.email },
      });

      const result = await firebase.auth().currentUser.getIdTokenResult(true);
      if (props.isCartLogin) {
        setTimeout(() => {
          props.moveToStep(1);
          setSubmitting(false);
        }, 300);

        return;
      }

      if (result.claims["https://hasura.io/jwt/claims"] == null) {
        firebase.auth().signOut();
        window.location.href = "/sign-out";
        throw "There is an issue with your user account. Please contact customer support for assistance at (316) 202-8558.";
      }

      let default_role =
        result.claims["https://hasura.io/jwt/claims"]["x-hasura-default-role"];

      if (querystring.get("r")) {
        window.location.href = querystring.get("r");
      } else {
        window.location.href =
          default_role != "patient"
            ? "/my-account"
            : window.location.href.indexOf("/sign-in") != -1
            ? "/"
            : window.location.href;
      }
    } catch (error) {
      if (
        error.code == "auth/wrong-password" ||
        error.code == "auth/user-not-found"
      ) {
        setLoginError("Incorrect email or password. Please try again.");
      } else if (error.code == "auth/user-disabled") {
        setLoginError(
          "The user account has been disabled by an administrator. Please contact customer support for assistance at (316) 202-8558."
        );
      } else {
        setLoginError(
          "There was an error with logging in. " + JSON.stringify(error)
        );
      }
      setSubmitting(false);
    }

    // .then((idTokenResult) => {

    //   // firebase
    //   //   .auth()
    //   //   .currentUser.getIdToken(true)
    //   //   .then(async (idToken) => {

    //   //   });
    // })
    // .catch((error) => {

    // });
  };

  return (
    <Modal
      show={props.modalOpen}
      size="sm"
      className="mt-5"
      backdrop="static"
      onHide={() => {
        props.setModalOpen(false);
      }}
    >
      {showPasswordRecovery ? (
        <>
          <Modal.Header closeButton={false}>
            <Modal.Title as="h5">Forgot Your Password?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              enableReinitialize
              initialValues={{}}
              onSubmit={async (values, { setSubmitting }) => {
                // send password recovery email

                let sendResetResult = await sendReset({
                  variables: { email: values.email },
                });

                if (sendResetResult.data.resetPasswordSend.success) {
                  setShowRecoverySent(true);
                } else {
                  setLoginError(sendResetResult.data.resetPasswordSend.message);
                }
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Must be a valid email address")
                  .required("Email is required"),
              })}
              render={({
                form,
                values,
                handleSubmit,
                handleChange,
                handleBlur,
                errors,
                touched,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {showRecoverySent ? (
                    <>
                      <Form.Group>
                        <Form.Text>
                          We have sent a reset password email to{" "}
                          <strong>{values.email}</strong>. Please click the link
                          provided in the email to set up your new password.
                        </Form.Text>
                      </Form.Group>
                    </>
                  ) : (
                    <>
                      <Form.Group>
                        <Form.Text>
                          Please enter the email address you used when you
                          registered below:
                        </Form.Text>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          tabIndex={1}
                          type="text"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            touched.email && errors.email ? "error" : null
                          }
                        />
                        {touched.email && errors.email ? (
                          <div className="text-danger">{errors.email}</div>
                        ) : null}
                      </Form.Group>

                      {loginError && (
                        <div className="text-danger mb-3">{loginError}</div>
                      )}

                      <Form.Group>
                        <Button
                          type="submit"
                          variant="primary"
                          className="btn-block"
                          tabIndex={3}
                        >
                          Send Password Recovery Email
                        </Button>
                      </Form.Group>

                      <Form.Group className="mt-2">
                        <Button
                          type="button"
                          variant="light"
                          className="btn-block"
                          onClick={() => {
                            setShowPasswordRecovery(false);
                          }}
                        >
                          Show Sign In
                        </Button>
                      </Form.Group>
                    </>
                  )}
                </Form>
              )}
            />
          </Modal.Body>
        </>
      ) : (
        <>
          <Modal.Header closeButton={false}>
            <Modal.Title as="h5">Sign In to Your Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              enableReinitialize
              initialValues={{}}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values, setSubmitting);
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .trim()
                  .email("Must be a valid email address")
                  .required("Email is required"),
                password: Yup.string().trim().required("Password is required"),
              })}
              render={({
                form,
                values,
                handleSubmit,
                handleChange,
                handleBlur,
                errors,
                touched,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      id="signin-input-email"
                      tabIndex={1}
                      type="text"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={touched.email && errors.email ? "error" : null}
                    />
                    {touched.email && errors.email ? (
                      <div className="text-danger">{errors.email}</div>
                    ) : null}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="d-flex justify-content-between align-items-end">
                      <div>Password</div>
                      <a
                        href="javascript:;"
                        onClick={() => {
                          setShowPasswordRecovery(true);
                        }}
                        className="d-block small"
                        tabIndex={100}
                      >
                        Forgot password?
                      </a>
                    </Form.Label>
                    <Form.Control
                      id="signin-input-password"
                      tabIndex={2}
                      type="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        touched.password && errors.password ? "error" : null
                      }
                    />
                    {touched.password && errors.password ? (
                      <div className="text-danger">{errors.password}</div>
                    ) : null}
                  </Form.Group>
                  <Form.Group>
                    <Button
                      type="submit"
                      variant="primary"
                      className="btn-block"
                      tabIndex={3}
                    >
                      Sign In
                    </Button>
                  </Form.Group>
                  {loginError && (
                    <div className="text-danger">{loginError}</div>
                  )}
                </Form>
              )}
            />
          </Modal.Body>
          {/* <Modal.Footer>
            <div className="mx-auto text-center text-muted">
              Don't have an account yet? <Link to="/sign-up">Sign Up</Link>
            </div>
          </Modal.Footer> */}
        </>
      )}
    </Modal>
  );
}

const SEND_RESET = gql`
  mutation SetResetKey($email: String!) {
    resetPasswordSend(email: $email) {
      message
      success
    }
  }
`;

const UPDATE_LAST_LOGIN = gql`
  mutation UpdateUserLastLogin($email: String!) {
    updateUserLastLogin(email: $email) {
      message
      success
    }
  }
`;
