export const menuItems = {
  admin: {
    "audit-log": {
      name: "Audit Log",
      path: "/audit-log",
    },
    "bundle-components": {
      name: "Bundle Components",
      path: "/admin/bundle-components",
    },
    bundles: {
      name: "Bundles",
      path: "/admin/bundles",
    },
    "bundle-templates": {
      name: "Bundle Templates",
      path: "/admin/bundletemplates",
    },
    "permission-templates": {
      name: "Permission Templates",
      path: "/admin/permissiontemplates",
    },
    "practice-areas": {
      name: "Practice Areas",
      path: "/admin/practice-areas",
    },
    "pricing-components": {
      name: "Pricing Components",
      path: "/admin/pricing-components",
    },
    "roster-imports": {
      name: "Roster Imports",
      path: "/admin/roster-imports",
    },
    "xml-import": {
      name: "Provider Service Import",
      path: "/admin/xml-import",
    },
    "patient-offer-report": {
      name: "Patient Offer Report",
      path: "/admin/patient-offer-report",
    },
    "provider-groups": {
      name: "Provider Groups",
      path: "/admin/providergroups",
    },
    "service-bulk-update": {
      name: "Service Bulk Update",
      path: "/admin/service-bulk-update",
    },
    "service-categories": {
      name: "Service Categories",
      path: "/admin/categories",
    },
    users: {
      name: "Users",
      path: "/admin/users",
    },
  },
  employer: {
    dashboard: {
      name: "Dashboard",
      path: "/employer/dashboard",
      icon: "ion ion-md-speedometer",
    },
    "health-plans": {
      name: "Health Plans",
      path: "/employer/plans",
      icon: "fas fa-stethoscope",
    },
    orders: {
      name: "Orders",
      path: "/employer/orders",
      icon: "fas fa-shopping-cart",
    },
    payments: {
      name: "Payments",
      path: "/employer/payments",
      icon: "fas fa-money-bill-wave-alt",
    },
    "my-details": {
      name: "Employer Profile",
      path: "/employer/my-details",
      icon: "fas fa-align-left",
    },
  },
  provider: {
    "provider-orders": {
      name: "Orders",
      path: "/provider/orders",
      icon: "fas fa-users",
    },
    "provider-patients": {
      name: "Patients",
      path: "/provider/patients",
      icon: "fas fa-shopping-cart",
    },
    "group-providers": {
      name: "Profiles",
      path: "/groupproviders",
      icon: "fas fa-stethoscope",
    },
    welcome: {
      name: "Welcome",
      path: "/welcome",
      icon: "fas fa-smile",
    },
  },
  reports: {
    "missing-nap-report": {
      name: "Missing NAP Report",
      path: "/reports/missingnapreport",
    },
    "payout-report": {
      name: "Payout Report",
      path: "/reports/payoutreport",
    },
    "payout-escrow-report": {
      name: "Payout Escrow Report",
      path: "/reports/payoutescrowreport",
    },
    "patients-report": {
      name: "Patients Report",
      path: "/reports/patientsreport",
    },
    "search-report": {
      name: "Search Report",
      path: "/reports/searchreport",
    },
    "survey-results-report": {
      name: "Survey Results Report",
      path: "/reports/surveyresultsreport",
    },
  },
  employers: {
    name: "Employers",
    path: "/employers",
    icon: "fas fa-city",
  },
  feedback: {
    name: "Support / Feedback",
    path: "/feedback",
    icon: "fa fa-comment-dots",
  },
  orders: { name: "Orders", path: "/orders", icon: "fas fa-shopping-cart" },
  patients: { name: "Patients", path: "/patients", icon: "fas fa-users" },
  providers: {
    name: "Providers",
    path: "/providers",
    icon: "fas fa-stethoscope",
  },
  services: {
    name: "Services",
    path: "/catalog/procedures",
    icon: "fas fa-briefcase-medical",
  },
};
