import React, { Component } from "react";
import { connect } from "react-redux";

export default function LayoutFooter(props) {
  const d = new Date();

  function prevent(e) {
    e.preventDefault();
  }

  return (
    <nav className="layout-footer footer bg-footer-theme">
      <div className="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
        <div className="pt-3">
          <span className="footer-text">
            © {d.getFullYear()} Fair Market Health
          </span>
        </div>
        <div>
          {" "}
          <small className="opacity-25">
            {/* <span
              onClick={() => {
                localStorage.removeItem("userType");
                window.location.reload();
              }}
            >
              Agent
            </span>{" "}
            <span
              onClick={() => {
                localStorage.setItem("userType", "Provider");
                window.location.reload();
              }}
            >
              Provider
            </span>{" "}
            <span
              onClick={() => {
                localStorage.setItem("userType", "Employer");
                window.location.reload();
              }}
            >
              Employer
            </span> */}
          </small>
          <a
            href="/terms-of-use"
            target="_blank"
            className="footer-link pt-3 ml-4"
          >
            Terms of Service
          </a>
          <a
            href="/privacy-policy"
            target="_blank"
            className="footer-link pt-3 ml-4 mr-5"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </nav>
  );
}
