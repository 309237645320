import React from "react";
import Sidenav from "../../../../vendor/libs/sidenav";
import { ProviderReportsMenu } from "./ProviderReportsMenu";
import { ProviderAdminMenu } from "./ProviderAdminMenu";
import { NavMenuItemLink } from "../navigation-menu-items/NavMenuItemLink";

export default function ProviderToolsMenu(props) {
  const menuItems = ["provider/welcome"];

  if (props.permissions.indexOf("ORD_VIEW") > -1) {
    menuItems.push("provider/provider-orders");
  }

  if (props.permissions.indexOf("PAT_VIEW") > -1) {
    menuItems.push("provider/provider-patients");
  }

  if (props.permissions.indexOf("PROV_VIEW") > -1) {
    menuItems.push("provider/group-providers");
  }

  return (
    <>
      <Sidenav.Header className="small font-weight-semibold">
        PROVIDER TOOLS
      </Sidenav.Header>
      {menuItems.map((menuItem) => {
        return (
          <NavMenuItemLink
            key={menuItem}
            name={menuItem}
            clearAllFilters={props.clearAllFilters}
          />
        );
      })}

      {/**  Reports */}
      {props.permissions.indexOf("REP_PAYOUT_VIEW") > -1 && (
        <ProviderReportsMenu
          clearAllFilters={props.clearAllFilters}
          isMenuOpen={props.isMenuOpen}
          isMenuActive={props.isMenuActive}
        />
      )}
      {/**  Admin */}
      {props.permissions.indexOf("USER_VIEW") > -1 && (
        <ProviderAdminMenu
          clearAllFilters={props.clearAllFilters}
          isMenuOpen={props.isMenuOpen}
          isMenuActive={props.isMenuActive}
          permissions={props.permissions}
        />
      )}
    </>
  );
}
