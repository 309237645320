import React from "react";
import Sidenav from "../../../../vendor/libs/sidenav";
import { NavMenuItemLink } from "../navigation-menu-items/NavMenuItemLink";

export default function AdditionalToolsMenu(props) {
  return (
    <>
      <Sidenav.Header className="small font-weight-semibold">
        ADDITIONAL TOOLS
      </Sidenav.Header>
      <NavMenuItemLink
        name="feedback"
        clearAllFilters={props.clearAllFilters}
      />
    </>
  );
}
