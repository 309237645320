import React, { Component } from "react";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import {
  BrowserRouter as AppRouter,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import history from "../shared/history";
import NotFound from "./NotFound";
import Layout2 from "../shared/layouts/Layout2";

// Routes
import { DefaultLayout, titleTemplate, defaultRoute, routes } from "../routes";
import ModalQuickAdd from "./modals/ModalQuickAdd";
import Loader from "./Loader";

const env = runtimeEnv();

class Router extends Component {
  constructor(props) {
    super(props);

    this.showModal = this.showModal.bind(this);
    this.closeModals = this.closeModals.bind(this);

    // Set default layout
    this.routes = routes.map((route) => {
      route.layout = route.layout || DefaultLayout;
      route.exact = typeof route.exact === "undefined" ? true : route.exact;
      return route;
    });

    // Set app loading class
    document.documentElement.classList.add("app-loading");

    this.state = {
      id: 0,
      showModalPhoneCall: false,
      showModalSMS: false,
      showModalEmail: false,
      showModalVoicemail: false,
      showQuickAdd: false,
      showModalToday: false,
      showModalTask: false,
    };
  }

  componentDidMount() {
    const removeLoadingClass = () => {
      document.documentElement.classList.remove("app-loading");
    };

    // Remove splash screen
    const splashScreen = document.querySelector(".app-splash-screen");
    if (splashScreen) {
      splashScreen.style.opacity = 0;
      setTimeout(() => {
        splashScreen && splashScreen.parentNode.removeChild(splashScreen);
        removeLoadingClass();
      }, 300);
    } else {
      removeLoadingClass();
    }
  }

  setTitle(title) {
    document.title = titleTemplate.replace("%s", title);
  }

  showModal(modal, id) {
    if (modal === "QuickAdd") {
      this.setState({ showQuickAdd: true });
    }
    if (modal === "ModalToday") {
      this.setState({ showModalToday: true });
    }
    if (modal === "Phone") {
      this.setState({ showModalPhoneCall: true });
    }
    if (modal === "SMS") {
      this.setState({ showModalSMS: true });
    }
    if (modal === "Email") {
      this.setState({ showModalEmail: true });
    }
    if (modal === "Voicemail") {
      this.setState({ showModalVoicemail: true });
    }
    if (modal === "Task") {
      this.setState({ showModalTask: true });
      this.setState({ id: id });
    }
  }

  closeModals() {
    this.setState({
      showModalPhoneCall: false,
      showModalSMS: false,
      showModalEmail: false,
      showModalVoicemail: false,
      showQuickAdd: false,
      showModalToday: false,
      showModalTask: false,
    });
  }

  scrollTop(
    to,
    duration,
    element = document.scrollingElement || document.documentElement
  ) {
    if (element.scrollTop === to) return;
    const start = element.scrollTop;
    const change = to - start;
    const startDate = +new Date();

    if (!duration) {
      element.scrollTop = to;
      return;
    }

    // t = current time; b = start value; c = change in value; d = duration
    const easeInOutQuad = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const animateScroll = () => {
      const currentDate = +new Date();
      const currentTime = currentDate - startDate;
      element.scrollTop = parseInt(
        easeInOutQuad(currentTime, start, change, duration)
      );
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      } else {
        element.scrollTop = to;
      }
    };

    animateScroll();
  }

  render() {
    return (
      <AppRouter basename={process.env.REACT_APP_BASENAME} history={history}>
        <Switch>
          {this.routes.map((route) => (
            <Route
              path={route.path}
              exact={route.exact}
              render={(props) => {
                // On small screens collapse sidenav
                if (
                  window.layoutHelpers &&
                  window.layoutHelpers.isSmallScreen()
                ) {
                  window.layoutHelpers.setCollapsed(true, false);
                }

                // Scroll page to top on route render
                this.scrollTop(0, 0);

                if (this.props.user == "init") {
                  return (
                    <div className="container flex-wrap container-p-y">
                      <Row>
                        <Col md={12}>
                          <Loader />
                        </Col>
                      </Row>
                    </div>
                  );
                }

                if (route.protected && this.props.user == null) {
                  // window.location.href =
                  //   process.env.REACT_APP_PUBLIC_SITE_URL + "/sign-out";
                  window.location.href =
                    "/sign-in?r=" +
                    window.location.pathname +
                    window.location.search;

                  return (
                    <route.layout {...props} showModal={this.showModal}>
                      <div className="container flex-wrap container-p-y">
                        <Row>
                          <Col md={12}>
                            <Loader />
                          </Col>
                        </Row>
                      </div>
                    </route.layout>
                  );
                }

                // Return layout
                return (
                  <route.layout
                    {...props}
                    claims={this.props.claims}
                    setClaims={this.props.setClaims}
                    protected={route.protected}
                    showModal={this.showModal}
                  >
                    <route.component
                      {...props}
                      user={this.props.user}
                      claims={this.props.claims}
                      setTitle={this.setTitle}
                      scrollTop={this.scrollTop}
                      showModal={this.showModal}
                      close={this.closeModals}
                      routeVariables={route.routeVariables}
                    />
                  </route.layout>
                );
              }}
              key={route.path}
            />
          ))}
          {defaultRoute !== "/" && (
            <Redirect from="/" to={defaultRoute} exact={true} />
          )}

          {/* NotFound page */}

          <Route
            path="*"
            render={(props) => {
              // On small screens collapse sidenav
              if (
                window.layoutHelpers &&
                window.layoutHelpers.isSmallScreen()
              ) {
                window.layoutHelpers.setCollapsed(true, false);
              }

              // Scroll page to top on route render
              this.scrollTop(0, 0);

              // Return layout
              return (
                <Layout2 {...props}>
                  <NotFound {...props} />
                </Layout2>
              );
            }}
          />
        </Switch>
      </AppRouter>
    );
  }
}

export default Router;
