import React, { Component } from "react";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";
import {
  Navbar,
  Nav,
  FormControl,
  Dropdown,
  Badge,
  Button,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import layoutHelpers from "./helpers";
import firebase from "firebase/app";
import "firebase/auth";
import StripeACHActivation from "../../components/employer/StripeACHActivation";
import StripeActivation from "../../components/provider/StripeActivation";

import { useIdleTimer } from "react-idle-timer";
import IdleHandler from "./IdleHandler";

const env = runtimeEnv();

class LayoutNavbar extends Component {
  constructor(props) {
    super(props);
    this.isRTL = document.documentElement.getAttribute("dir") === "rtl";

    this.closeQuickAdd = this.closeQuickAdd.bind(this);

    this.state = {
      showQuickAdd: false,
      //searchTerm: "",
    };
  }

  closeQuickAdd() {
    this.setState({ showQuickAdd: false });
  }

  toggleSidenav(e) {
    e.preventDefault();
    layoutHelpers.toggleCollapsed();
  }

  render() {
    if (this.props.claims === null) {
      return null;
    }

    return (
      <>
        {/*this.state.searchTerm.length > 4 && (
           <Redirect push to="/search-results" />
        */}
        <Navbar
          bg={this.props.navbarBg}
          expand="lg"
          className="layout-navbar align-items-lg-center container-p-x"
        >
          {/* Sidenav toggle (see src/demo.css) */}
          {this.props.sidenavToggle && (
            <Nav className="layout-sidenav-toggle d-lg-none align-items-lg-center mr-3">
              <Nav.Item
                as="a"
                className="nav-link px-0 mr-lg-4"
                href="#toggle"
                onClick={this.toggleSidenav}
              >
                <i className="ion ion-md-menu text-large align-middle"></i>
              </Nav.Item>
            </Nav>
          )}

          {/* Brand demo (see src/demo.css) */}
          <Navbar.Brand
            as={NavLink}
            to="/"
            className="app-brand demo d-lg-none py-0"
          >
            <img
              src={`/img/logo.png`}
              alt="Fair Market Health"
              className="app-brand-logo demo"
            />
          </Navbar.Brand>

          <Navbar.Toggle>
            <i className="fas fa-chevron-down"></i>
          </Navbar.Toggle>

          <Navbar.Collapse>
            {/* Divider */}

            <hr className="d-lg-none w-100 my-2" />

            <Nav className="align-items-lg-center">
              {/* Search */}
              {/*<label className="nav-item navbar-text navbar-search-box p-0 active">*/}
              {/*  <i className="ion ion-ios-search navbar-icon align-middle"></i>*/}
              {/*  <span className="navbar-search-input pl-2">*/}
              {/*    <FormControl*/}
              {/*      className="navbar-text mx-2"*/}
              {/*      placeholder="Search..."*/}
              {/*      style={{ width: "200px" }}*/}
              {/*      value={this.state.searchTerm}*/}
              {/*      onChange={(e) => {*/}
              {/*        this.setState({ searchTerm: e.target.value });*/}
              {/*        this.props.setSearchTerm(e.target.value);*/}
              {/*      }}*/}
              {/*    />*/}
              {/*  </span>*/}
              {/*</label>*/}
            </Nav>
            <Nav className="align-items-lg-center ml-auto">
              {this.props.claims.default_role === "employer" &&
                this.props.claims.permissions.indexOf("EMP_BANK") > -1 && (
                  <>
                    <Nav.Link className="demo-navbar-notifications mr-lg-2">
                      <div style={{ position: "relative" }}>
                        <StripeACHActivation
                          claims={this.props.claims}
                          employer_id={this.props.claims.employer_id}
                        />
                      </div>
                    </Nav.Link>
                    {/* Divider */}
                    <div className="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-50 mr-3 ml-1">
                      |
                    </div>
                  </>
                )}
              {this.props.claims.default_role === "provider" &&
                this.props.claims.permissions.indexOf("PROV_BANK") > -1 && (
                  <>
                    <Nav.Link className="demo-navbar-notifications mr-lg-2">
                      <div style={{ position: "relative" }}>
                        <StripeActivation claims={this.props.claims} />
                      </div>
                    </Nav.Link>
                  </>
                )}

              {this.props.claims.default_role === "agentold" && (
                <>
                  <Nav.Link
                    className="demo-navbar-notifications mr-lg-2"
                    onClick={() => this.props.showModal("QuickAdd")}
                  >
                    <i className="ion ion-md-add-circle navbar-icon align-middle"></i>
                    <span className="d-lg-none align-middle">
                      &nbsp; Quick Add
                    </span>
                  </Nav.Link>

                  <LinkContainer to="/tasks">
                    <Nav.Link className="demo-navbar-notifications mr-lg-2">
                      <i className="fas fa-list-alt navbar-icon align-middle"></i>
                      <Badge variant="danger badge-dot indicator"></Badge>
                      <span className="d-lg-none align-middle">
                        &nbsp; Tasks
                      </span>
                    </Nav.Link>
                  </LinkContainer>

                  <LinkContainer to="/calendar">
                    <Nav.Link className="demo-navbar-notifications mr-lg-2">
                      <i className="ion ion-md-calendar navbar-icon align-middle"></i>
                      <Badge variant="danger badge-dot indicator"></Badge>
                      <span className="d-lg-none align-middle">
                        &nbsp; Calendar Events
                      </span>
                    </Nav.Link>
                  </LinkContainer>

                  {/* Divider */}
                  <div className="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-50 mr-3 ml-1">
                    |
                  </div>
                </>
              )}

              <Dropdown
                as={Nav.Item}
                className="demo-navbar-user"
                alignRight={!this.isRTL}
              >
                <Dropdown.Toggle as={Nav.Link}>
                  <span className="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                    <img
                      src={`/img/defaultavatar.jpg`}
                      className="d-block ui-w-30 rounded-circle"
                      alt="User"
                    />
                    <span className="px-1 mr-lg-2 ml-2 ml-lg-0">
                      {this.props.claims["email"]}
                    </span>
                  </span>
                </Dropdown.Toggle>
                <IdleHandler claims={this.props.claims} />
                <Dropdown.Menu>
                  <Dropdown.Item href="/sign-out">
                    <i
                      className="fas fa-sign-out-alt text-danger"
                      style={{ width: "15px" }}
                    ></i>{" "}
                    &nbsp; Log Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}

LayoutNavbar.propTypes = {
  sidenavToggle: PropTypes.bool,
};

LayoutNavbar.defaultProps = {
  sidenavToggle: true,
};

export default connect((store) => ({
  navbarBg: store.theme.navbarBg,
}))(LayoutNavbar);
