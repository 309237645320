import React from "react";
import Sidenav from "../../../../vendor/libs/sidenav";
import { NavMenuItemLink } from "../navigation-menu-items/NavMenuItemLink";

export const EmployerAdminMenu = (props) => {
  let menuItems = [];

  if (props.permissions.indexOf("USER_VIEW") > -1) {
    menuItems.push("admin/users");
  }

  return (
    <Sidenav.Menu
      icon="fas fa-cog"
      linkText="Admin"
      active={props.isMenuActive("/admin")}
      open={props.isMenuOpen("/admin")}
    >
      {menuItems.map((menuItem) => {
        return (
          <NavMenuItemLink
            key={menuItem}
            name={menuItem}
            clearAllFilters={props.clearAllFilters}
          />
        );
      })}
    </Sidenav.Menu>
  );
};
