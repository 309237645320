import { useParams, useHistory, useLocation } from "react-router-dom";

export function useBrowserQuery() {
  return new URLSearchParams(useLocation().search);
}

export function getShortUrlDomain() {
  if (window.location.href.indexOf("localhost") > -1) {
    return "localhost:3001";
  } else if (
    window.location.href.indexOf("staging-my.fairmarkethealth.com") > -1
  ) {
    return "staging.fmh.link";
  } else if (
    window.location.href.indexOf("demo-my.fairmarkethealth.com") > -1
  ) {
    return "demo.fmh.link";
  } else {
    return "fmh.link";
  }
}
