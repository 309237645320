import React, { useEffect, useState, useRef, useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Button } from "react-bootstrap";
import { UserContext } from "../../auth/FirebaseUserProvider";
import gql from "graphql-tag";

export default function StripeActivation(props) {
  const user = useContext(UserContext);
  const { loading, data, error } = useQuery(QUERY, {
    fetchPolicy: "no-cache",
    variables: { id: props.claims.provider_id },
  });

  if (loading) return null;

  if (user != null && data.provider[0] !== null) {
    if (data.provider[0].stripe_id !== null) {
      return null;
    } else {
      return (
        <Button
          size="sm"
          variant="danger"
          onClick={() => {
            window.location.href =
              "https://dashboard.stripe.com/express/oauth/authorize?response_type=code&client_id=" +
              process.env.REACT_APP_STRIPE_CONNECT_KEY +
              "&scope=read_write&redirect_uri=" +
              process.env.REACT_APP_BASE_URL +
              "/provider-sign-up-complete";
          }}
        >
          <i className="fa fa-exclamation-triangle mr-2"></i> Activate My
          Account
        </Button>
      );
    }
  }

  return null;
}

export const QUERY = gql`
  query GetProvider($id: Int) {
    provider(where: { id: { _eq: $id } }) {
      id
      email
      stripe_id
    }
  }
`;
