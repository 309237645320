import React, { useState, useContext } from "react";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Row, Col, Navbar } from "react-bootstrap";
import { UserContext } from "../../auth/FirebaseUserProvider";
import "firebase/auth";
import SignInModal from "../../components/public/SignInModal";

const checkActive = (match, location) => {
  //some additional logic to verify you are in the home URI
  if (!location) return false;
  const { pathname } = location;
  return pathname === "/";
};

export default function LayoutNavbarPublicNoNav(props) {
  let { slug, id, group_slug } = useParams();
  const { loading, data } = useQuery(QUERY, {
    fetchPolicy: "no-cache",
    context: {
      clientName: "anonymous",
    },
    variables: {
      slug: group_slug ? group_slug : slug == null ? "" : slug,
      provider_procedure_id: slug == null && id != null ? id : 0,
    },
  });

  const env = runtimeEnv();
  const [modalOpen, setModalOpen] = useState(false);
  const user = useContext(UserContext);
  let history = useHistory();
  let href = window.location.pathname + window.location.search;

  function mouseOverMenuItem(event) {
    event.target.style.color = "#000000";
  }

  function mouseOutMenuItem(event) {
    event.target.style.color = "#a3a4a6";
  }

  if (loading) {
    return null;
  }

  let page_title = "";
  let logo = "";
  let providerGroupId = null;

  if (data && data.provider[0] != null) {
    page_title = data.provider[0].provider_full_name;
  }

  if (data && data.employer_plan[0] != null) {
    if (user == null && data.employer_plan[0].id != 1) {
      history.push(`/employee-activation/${data.employer_plan[0].employer_id}`);
      return null;
    }
    slug = data.employer_plan[0].slug;
    page_title = data.employer_plan[0].employer.name;
    logo = data.employer_plan[0].employer.logo_url;
  }

  if (data && data.provider_group[0] != null) {
    providerGroupId = data.provider_group[0].id;
    page_title = data.provider_group[0].name;
    logo = data.provider_group[0].logo_url;
    slug = data.provider_group[0].slug;
  }

  return (
    <>
      <Navbar
        bg="white"
        expand="lg"
        className="layout-navbar align-items-lg-center container-p-x py-3"
      >
        <div className="container d-block">
          <Row>
            <Col md={4}>
              <Navbar.Brand
                as={NavLink}
                to={href}
                className="app-brand demo d-lg-none py-0"
                isActive={checkActive}
              >
                <img
                  src={`/img/logo.png`}
                  alt="Fair Market Health"
                  className="d-none d-md-block app-brand-logo demo mr-3"
                  style={{ height: "58px" }}
                />
                <img
                  src={`/img/logo.png`}
                  alt="Fair Market Health"
                  className="d-sm-none app-brand-logo demo mr-3"
                  style={{ height: "38px" }}
                />
              </Navbar.Brand>
            </Col>
            <Col md={4}>
              <p className="text-center mt-2" style={{ fontSize: "1.5rem" }}>
                <b>{page_title}</b>
              </p>
            </Col>
            <Col md={4} className="text-right">
              <>
                {providerGroupId != 1 ||
                  ((slug == null || slug.toString().trim() == "") && logo && (
                    <img
                      src={logo}
                      alt={page_title}
                      className="d-none d-md-block float-right"
                      style={{ height: "58px", backgroundColor: "black" }}
                    />
                  ))}
                {providerGroupId == 1 && (
                  <div>
                    {logo && (
                      <img
                        src={logo}
                        alt={page_title}
                        className="d-none d-md-block float-right"
                        style={{
                          height: "58px",
                          backgroundColor: "black",
                          marginBottom: "20px",
                        }}
                      />
                    )}
                    <div
                      style={{
                        clear: "right",
                        width: "320px",
                        marginTop: "20px",
                      }}
                    >
                      <a
                        href="tel:+13162028558"
                        style={{ marginRight: "20px" }}
                      >
                        <span
                          style={{ color: "#a3a4a6" }}
                          onMouseOver={mouseOverMenuItem}
                          onMouseOut={mouseOutMenuItem}
                        >
                          (316) 202-8558
                        </span>
                      </a>
                      <a
                        style={{ marginRight: "20px" }}
                        href={
                          slug != null && slug.toString().trim() != ""
                            ? slug
                            : "search"
                        }
                      >
                        <i
                          style={{ color: "#a3a4a6" }}
                          onMouseOver={mouseOverMenuItem}
                          onMouseOut={mouseOutMenuItem}
                          className="ion ion-ios-search navbar-icon align-middle"
                        ></i>
                      </a>
                      <a
                        style={{ marginRight: "20px", marginTop: "9px" }}
                        href="/cart"
                      >
                        <i
                          style={{ color: "#a3a4a6" }}
                          onMouseOver={mouseOverMenuItem}
                          onMouseOut={mouseOutMenuItem}
                          className="sidenav-icon fas fa-shopping-cart"
                        ></i>
                      </a>
                      <span style={{ marginRight: "20px", color: "#a3a4a6" }}>
                        |
                      </span>
                      {user == null && (
                        <span
                          onClick={() => setModalOpen(true)}
                          onMouseOver={mouseOverMenuItem}
                          onMouseOut={mouseOutMenuItem}
                          style={{ marginRight: "20px", cursor: "pointer" }}
                        >
                          <span>Sign In</span>
                        </span>
                      )}
                      {user != null && (
                        <span
                          onClick={() => (window.location.href = "/my-account")}
                          onMouseOver={mouseOverMenuItem}
                          onMouseOut={mouseOutMenuItem}
                          style={{ marginRight: "20px", cursor: "pointer" }}
                        >
                          <span>My Account</span>
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </>
            </Col>
          </Row>
        </div>
      </Navbar>
      <SignInModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </>
  );
}

const QUERY = gql`
  query GetProvider($slug: String, $provider_procedure_id: Int) {
    provider(
      where: {
        _or: [
          { slug: { _eq: $slug } }
          { provider_procedures: { id: { _eq: $provider_procedure_id } } }
        ]
      }
    ) {
      id
      provider_full_name
    }
    employer_plan(where: { slug: { _eq: $slug } }) {
      id
      employer_id
      slug
      employer {
        name
        logo_url
      }
    }
    provider_group(where: { slug: { _eq: $slug } }) {
      id
      name
      slug
      logo_url
    }
  }
`;
