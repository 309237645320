import React, { useState, useEffect } from "react";
import LayoutNavbar from "./LayoutNavbar";
import LayoutSidenav from "./side-navigation/LayoutSidenav";
import LayoutFooter from "./LayoutFooter";
import layoutHelpers from "./helpers";
import ErrorBoundary from "../../components/common/ErrorBoundary";
import { ToastContainer } from "react-toastify";
import gql from "graphql-tag";
import useLocalStorage from "../../utils/localStorageUtils";
import { useImperativeQuery } from "../../utils/gqlutils";

export default function Layout2(props) {
  const getPermissions = useImperativeQuery(PERMISSIONS_QUERY);
  const [searchTerm, setSearchTerm] = useState("");
  const [userEmail, setUserEmail] = useState(
    props.claims ? props.claims.email : ""
  );

  useEffect(() => {
    layoutHelpers.init();
    layoutHelpers.update();
    layoutHelpers.setAutoUpdate(true);

    return function cleanup() {
      layoutHelpers.destroy();
    };
  }, []);

  useEffect(() => {
    // set permissions on claim object
    if (
      props.claims &&
      props.claims.email &&
      props.claims.email !== userEmail
    ) {
      setUserEmail(props.claims.email);
      getPermissions({
        email: props.claims.email,
      }).then((result) => {
        let claims = JSON.parse(JSON.stringify(props.claims));
        claims.permissions = result.data.user[0].user_permissions.map(
          (n) => n.permission.key
        );
        let total_providers = result.data.user[0].user_providers.length;

        // only set this if this is a single provider user
        claims.provider_id =
          total_providers == 1
            ? result.data.user[0].user_providers[0]?.provider_id
            : "";
        // this allows for multiprovider users to still use the welcome screen
        claims.first_provider_id =
          result.data.user[0].user_providers[0]?.provider_id || "";
        claims.hide_self_boarding_screen =
          result.data.user[0].user_providers[0]?.provider?.hide_self_boarding_screen;
        claims.broker_uses_fmh_provider_marketplace =
          result.data.user[0].broker_uses_fmh_provider_marketplace;
        props.setClaims(claims);
      });
    }
  }, [props.claims]);

  function closeSidenav(e) {
    e.preventDefault();
    layoutHelpers.setCollapsed(true);
  }

  if (!props.claims || props.claims.default_role === "") {
    return null;
  }

  if (
    props.claims != null &&
    props.claims.default_role !== "" &&
    props.claims.default_role === "patient"
  ) {
    window.location.href = "/my-account";
    return null;
  }

  return (
    <div className="layout-wrapper layout-2">
      <div className="layout-inner">
        <LayoutSidenav {...props} />

        <div className="layout-container">
          <LayoutNavbar {...props} setSearchTerm={setSearchTerm} />

          <div className="layout-content">
            <div className="container-fluid flex-grow-1 container-p-y">
              <ErrorBoundary>{props.children}</ErrorBoundary>
            </div>

            <LayoutFooter {...props} />
          </div>
        </div>
      </div>
      <div className="layout-overlay" onClick={closeSidenav}></div>
      <ToastContainer autoClose={2} newestOnTop={true} />
    </div>
  );
}

const PERMISSIONS_QUERY = gql`
  query GetPermissions($email: String!) {
    user(where: { email: { _ilike: $email } }) {
      broker_uses_fmh_provider_marketplace
      user_providers {
        provider_id
        provider {
          hide_self_boarding_screen
        }
      }
      user_permissions {
        permission {
          key
        }
      }
    }
  }
`;
