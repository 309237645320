import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { useIdleTimer } from "react-idle-timer";

export default function IdleHandler(props) {
  let history = useHistory();

  const [modalProcedureOpen, setModalProcedureOpen] = useState(false);

  // Restrict an idle session if the role is something
  // that may have access to larger amounts of PHI
  // Basically only the patient is allowed an idle session
  const onIdle = () => {
    if (
      props.claims.default_role === "agent" ||
      props.claims.default_role === "provider" ||
      props.claims.default_role === "groupadmin" ||
      props.claims.default_role === "broker" ||
      props.claims.default_role === "employer" ||
      props.claims.default_role === "scheduler" ||
      props.claims.default_role === "agentold" // is this still used?
    ) {
      history.push("/sign-out");
    }
  };

  const onPrompt = () => {
    if (
      props.claims.default_role === "agent" ||
      props.claims.default_role === "provider" ||
      props.claims.default_role === "groupadmin" ||
      props.claims.default_role === "broker" ||
      props.claims.default_role === "employer" ||
      props.claims.default_role === "scheduler" ||
      props.claims.default_role === "agentold" // is this still used?
    ) {
      setModalProcedureOpen(true);
    }
  };

  const numIdleMinutes = 10;
  const numPromptSeconds = 30;
  const idleTimer = useIdleTimer({
    onIdle,
    onPrompt,
    timeout: 1000 * 60 * numIdleMinutes,
    promptTimeout: 1000 * numPromptSeconds,
  });

  return (
    <>
      <Modal
        show={modalProcedureOpen}
        backdrop="static"
        onHide={() => {
          setModalProcedureOpen(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">Idle User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your session has been idle past the allowed idle session settings.
          Please click the Close button to continue, or you will be
          automatically logged out.
        </Modal.Body>
        <Modal.Footer>
          <div className="mr-auto float-left">
            <Button
              className="mr-1"
              variant="secondary"
              onClick={() => setModalProcedureOpen(false)}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
