import React, { useEffect, useState, useRef, useCallback } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Button } from "react-bootstrap";
import gql from "graphql-tag";
import StripeACHStatusModal from "./StripeACHStatusModal";

export default function StripeACHActivation(props) {
  const [modalStatusOpen, setModalStatusOpen] = useState(false);
  const { loading, data, error } = useQuery(QUERY, {
    fetchPolicy: "no-cache",
    variables: { id: props.employer_id },
  });

  const [createEmployerStripeLink] = useMutation(CREATE_STRIPE_LINK, {
    refetchQueries: ["getEmployers", "getEmployerSetup"],
  });

  const [getEmployerSetupIntentStatus] = useMutation(GET_STRIPE_STATUS, {
    refetchQueries: ["getEmployers", "getEmployerSetup"],
  });

  useEffect(() => {
    var employer = data?.employer?.[0];
    if (
      employer &&
      employer.stripe_customer_id &&
      (employer.stripe_setup_status == null ||
        employer.stripe_setup_status == "requires_payment_method" ||
        employer.stripe_setup_status == "requires_confirmation" ||
        employer.stripe_setup_status == "requires_action" ||
        employer.stripe_setup_status == "processing")
    ) {
      getEmployerSetupIntentStatus({
        variables: {
          stripe_customer_id: employer.stripe_customer_id,
        },
      });
    }
  }, [data]);

  if (loading) return null;

  var employer = data.employer[0];

  var setup_status = employer.stripe_setup_status;
  //setupintent statuses: requires_payment_method, requires_confirmation, requires_action, processing, canceled, or succeeded
  return (
    <>
      {setup_status == "succeeded" && (
        <Button size="sm" variant="success">
          <i className="fa fa-check mr-2"></i> Your bank account is linked!
        </Button>
      )}

      {(setup_status == null || setup_status == "requires_payment_method") && (
        <Button
          size="sm"
          variant="danger"
          onClick={async () => {
            let linkResult = await createEmployerStripeLink({
              variables: {
                id: employer.id,
                email: props.claims.email,
              },
            });

            if (linkResult?.data?.createEmployerStripeLink?.success) {
              window.location.href =
                linkResult.data.createEmployerStripeLink.url;
            } else {
              window.alert(
                "There was an issue creating the onboarding link. Please contact support."
              );
            }
          }}
        >
          <i className="fa fa-exclamation-triangle mr-2"></i> Activate My
          Account
        </Button>
      )}

      {(setup_status == "requires_confirmation" ||
        setup_status == "requires_action" ||
        setup_status == "processing") && (
        <Button
          size="sm"
          variant="danger"
          onClick={async () => {
            setModalStatusOpen(true);
          }}
        >
          Account Pending (click for details)...
        </Button>
      )}
      {modalStatusOpen && (
        <StripeACHStatusModal
          modalOpen={modalStatusOpen}
          setModalOpen={setModalStatusOpen}
          employer_id={props.employer_id}
        />
      )}
    </>
  );
}

const CREATE_STRIPE_LINK = gql`
  mutation createEmployerStripeLink($id: Int!, $email: String!) {
    createEmployerStripeLink(employer_id: $id, email: $email) {
      success
      url
      message
    }
  }
`;

const GET_STRIPE_STATUS = gql`
  mutation getEmployerSetupIntentStatus($stripe_customer_id: String!) {
    getEmployerSetupIntentStatus(stripe_customer_id: $stripe_customer_id) {
      success
      message
      status
    }
  }
`;

const QUERY = gql`
  query getEmployerSetup($id: Int!) {
    employer(where: { id: { _eq: $id } }) {
      id
      name
      email
      stripe_customer_id
      stripe_setup_status
      stripe_setup_next_action
      stripe_setup_last_error
      stripe_setup_verification_url
    }
  }
`;
