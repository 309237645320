import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "localhost",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT,
});

export const auth = firebase.auth();
