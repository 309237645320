import { useState } from "react";

export default function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export function clearAllFilters() {
  let singleFilters = [
    "categories_sortByField",
    "categories_sortDirection",
    "categories_pageNumber",
    "categories_searchTerm",
    "procedures_sortByField",
    "procedures_sortDirection",
    "procedures_pageNumber",
    "procedures_searchTerm",
    "employers_sortByField",
    "employers_sortDirection",
    "employers_pageNumber",
    "employers_searchTerm",
    "orders_sortByField",
    "orders_sortDirection",
    "orders_pageSize",
    "orders_pageNumber",
    "orders_searchTerm",
    "orders_status",
    "patients_sortByField",
    "patients_sortDirection",
    "patients_pageNumber",
    "patients_searchTerm",
    "providers_sortByField",
    "providers_sortDirection",
    "providers_pageNumber",
    "providers_searchTerm",
  ];
  let rangeFilters = ["orders_dateRange"];
  singleFilters.forEach((e) => {
    window.localStorage.setItem(e, null);
  });
  rangeFilters.forEach((e) => {
    window.localStorage.setItem(e, [null, null]);
  });
}
