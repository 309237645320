import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { toast } from "react-toastify";
import { Row, Col, Card, Form, Button, Modal } from "react-bootstrap";

export default function StripeACHStatusModal(props) {
  const { loading, data, error } = useQuery(QUERY, {
    fetchPolicy: "no-cache",
    variables: { id: props.employer_id },
  });

  if (loading) {
    return null;
  }

  var employer = data?.employer?.[0];
  return (
    <Modal
      show={props.modalOpen}
      size="lg"
      backdrop="static"
      onHide={() => {
        props.setModalOpen(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">Employer ACH Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {employer.stripe_setup_next_action == "verify_with_microdeposits" && (
          <>
            <p>
              Your account must be activated by confirming a small deposit that
              was sent to the bank account you used when registering. Please
              click the button below to confirm the deposit.
            </p>

            <p>
              After completing the confirmation of the deposit, return here and
              click the "Refresh Account Status" button below.
            </p>
            <Button
              variant="success"
              onClick={() => {
                window.open(employer.stripe_setup_verification_url);
              }}
            >
              Confirm Deposits
            </Button>
          </>
        )}
      </Modal.Body>{" "}
      <Modal.Footer>
        <div className="mr-auto float-left">
          <Button
            variant="secondary"
            onClick={() => {
              window.location.reload();
            }}
          >
            Refresh Account Status
          </Button>
        </div>
        <Button
          className="mr-1"
          variant="secondary"
          onClick={() => props.setModalOpen(false)}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const QUERY = gql`
  query getEmployerSetup($id: Int!) {
    employer(where: { id: { _eq: $id } }) {
      id
      name
      email
      stripe_customer_id
      stripe_setup_status
      stripe_setup_next_action
      stripe_setup_last_error
      stripe_setup_verification_url
    }
  }
`;
