import React, { Component } from "react";
import LayoutNavbarPublic from "./LayoutNavbarPublic";
import LayoutFooterPublic from "./LayoutFooterPublic";
import layoutHelpers from "./helpers";
import { ToastContainer } from "react-toastify";
import LayoutNavbarPublicNoNav from "./LayoutNavbarPublicNoNav";

class LayoutWithoutSidenav extends Component {
  closeSidenav(e) {
    e.preventDefault();
    layoutHelpers.setCollapsed(true);
  }

  componentDidMount() {
    // Enshure that we have not '.layout-expanded' class on the html element
    layoutHelpers._removeClass("layout-expanded");

    layoutHelpers.init();
    layoutHelpers.update();
    layoutHelpers.setAutoUpdate(true);
  }

  componentWillUnmount() {
    layoutHelpers.destroy();
  }

  render() {
    if (
      this.props.protected &&
      this.props.claims != null &&
      this.props.claims.default_role != "" &&
      this.props.claims.default_role != "patient"
    ) {
      window.location.href = "/dashboard";
      return null;
    }

    return (
      <div className="layout-wrapper layout-1 layout-without-sidenav">
        <div className="layout-inner">
          <LayoutNavbarPublicNoNav sidenavToggle={false} {...this.props} />

          <div
            className="layout-container"
            style={{ paddingTop: "90px !important" }}
          >
            <div className="layout-content">
              {this.props.children}

              <LayoutFooterPublic {...this.props} />
            </div>
          </div>
        </div>
        <ToastContainer autoClose={2} newestOnTop={true} />
      </div>
    );
  }
}

export default LayoutWithoutSidenav;
