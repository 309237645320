import React, { useState, useContext, useEffect } from "react";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import Router from "./shared/Router";
import moment from "moment";
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient, HttpLink, InMemoryCache } from "apollo-boost";
import { WebSocketLink } from "apollo-link-ws";
import { split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { setContext } from "apollo-link-context";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { connect } from "react-redux";
import { UserContext } from "./auth/FirebaseUserProvider";
import * as JWT from "jwt-decode";
import { updateThemeSettings } from "./store/actions/themeActions";
import themeSettings from "./vendor/libs/theme-settings";
import "./vendor/libs/react-toastify/react-toastify.scss";
import "./App.scss";
import { TinaCMS, Tina } from "tinacms";
import { HtmlFieldPlugin, MarkdownFieldPlugin } from "react-tinacms-editor";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import Loader from "./shared/Loader";

function App(props) {
  const env = runtimeEnv();
  const [cms] = useState(() => {
    return new TinaCMS({
      toolbar: false,
      sidebar: false,
      // sidebar: {
      //   position: "overlay",
      // },
      // toolbar: {
      //   hidden: true,
      // },
    });
  });
  const [showSignOutIFrame, setShowSignOutIFrame] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowSignOutIFrame(false);
    }, 1000);
  }, []);

  //cms.plugins.add(HtmlFieldPlugin);
  //cms.plugins.add(MarkdownFieldPlugin);

  const user = useContext(UserContext);
  themeSettings._themeSettings.settings.onSettingsChange = () =>
    props.updateThemeSettings();

  const [claims, setClaims] = useState({
    token: "",
    user_id: 0,
    patient_id: 0,
    default_role: "",
    allowed_roles: [],
    permissions: [],
  });

  const getAccessToken = async (forceRefresh) => {
    const result = await user.getIdTokenResult(forceRefresh);

    if (result.claims["https://hasura.io/jwt/claims"] == null) {
      //firebase.auth().signOut();
      //window.location.href = "/sign-in";
    }

    setClaims({
      email: result.claims["email"],
      user_id:
        result.claims["https://hasura.io/jwt/claims"]["x-hasura-user-id"],
      provider_id:
        result.claims["https://hasura.io/jwt/claims"]["x-hasura-provider-id"],
      patient_id:
        result.claims["https://hasura.io/jwt/claims"]["x-hasura-patient-id"],
      employer_id:
        result.claims["https://hasura.io/jwt/claims"]["x-hasura-employer-id"],
      default_role:
        result.claims["https://hasura.io/jwt/claims"]["x-hasura-default-role"],
      allowed_roles:
        result.claims["https://hasura.io/jwt/claims"]["x-hasura-allowed-roles"],
      expires_on: result.expirationTime,
      token: result.token,
      permissions: [],
    });
  };

  if (user && user != "init" && claims.token == "") {
    getAccessToken(false);
  }

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL,
  });

  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_GRAPHQL_URL.replace("https:", "wss:"),
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: async () => {
        const result = await user.getIdTokenResult(false);
        return {
          headers: {
            Authorization:
              result && result.token ? `Bearer ${result.token}` : "",
          },
        };
      },
    },
  });

  const asyncAuthLink = setContext(async (e, operation) => {
    let headers = {};

    if (user === null) {
      return {
        headers: headers,
      };
    }
    const result = await user.getIdTokenResult(false);
    headers = {
      Authorization: `Bearer ${result.token}`,
    };

    if (operation.clientName) {
      headers["x-hasura-role"] = operation.clientName;
    }

    if (operation.clientName == "anonymous") {
      headers = {};
    }

    return {
      headers: headers,
    };
  });

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    asyncAuthLink.concat(httpLink)
  );

  const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
  });

  return (
    <Tina cms={cms} position="displace">
      <ApolloProvider client={client}>
        <Router user={user} claims={claims} setClaims={setClaims} />
      </ApolloProvider>
      {showSignOutIFrame && (
        <iframe
          width="1"
          height="1"
          frameBorder={0}
          src={`${process.env.REACT_APP_PUBLIC_SITE_URL}/sign-out`}
        ></iframe>
      )}
    </Tina>
  );
}

export default connect(null, { updateThemeSettings })(App);
