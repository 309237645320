import React, { useState, useEffect } from "react";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import Sidenav from "../../../vendor/libs/sidenav";
import ScrollArea from "react-scrollbar";
import layoutHelpers from "../helpers";
import { clearAllFilters } from "../../../utils/localStorageUtils";
import gql from "graphql-tag";
import { useImperativeQuery } from "../../../utils/gqlutils";

import AgentToolsMenu from "./agent/AgentToolsMenu";
import ProviderToolsMenu from "./provider/ProviderToolsMenu";
import EmployerToolsMenu from "./employer/EmployerToolsMenu";
import PartnerToolsMenu from "./partner/PartnerToolsMenu";
import AdditionalToolsMenu from "./additional-tools/AdditionalToolsMenu";
import HeaderSidenav from "./HeaderSidenav";

const env = runtimeEnv();

export default function LayoutSidenav(props) {
  const getBrokerUsesFMHProviderMarketplace = useImperativeQuery(QUERY);
  const [
    brokerUsesFMHProviderMarketplace,
    setBrokerUsesFMHProviderMarketplace,
  ] = useState(false);
  let permissions = props.claims ? props.claims.permissions : [];
  let isBroker = props.claims && props.claims.default_role == "broker";

  useEffect(() => {
    getBrokerUsesFMHProviderMarketplace({
      broker_user_id: props.claims.user_id != null ? props.claims.user_id : -1,
    }).then((result) => {
      setBrokerUsesFMHProviderMarketplace(
        result != null &&
          result.data != null &&
          result.data.user != null &&
          result.data.user.length > 0
      );
    });
  }, [props.claims]);

  function layoutSidenavClasses() {
    let bg = "sidenav-theme";

    if (
      props.orientation === "horizontal" &&
      (bg.indexOf(" sidenav-dark") !== -1 ||
        bg.indexOf(" sidenav-light") !== -1)
    ) {
      bg = bg
        .replace(" sidenav-dark", "")
        .replace(" sidenav-light", "")
        .replace("-darker", "")
        .replace("-dark", "");
    }

    return (
      `bg-${bg} ` +
      (props.orientation !== "horizontal"
        ? "layout-sidenav"
        : "layout-sidenav-horizontal container-p-x flex-grow-0")
    );
  }

  function toggleSidenav(e) {
    e.preventDefault();
    layoutHelpers.toggleCollapsed();
  }

  function isMenuActive(url) {
    return props.location.pathname.indexOf(url) === 0;
  }

  function isMenuOpen(url) {
    return (
      props.location.pathname.indexOf(url) === 0 &&
      props.orientation !== "horizontal"
    );
  }

  if (props.claims === null) {
    return null;
  }

  let rootDashboard = "/dashboard";

  if (props.claims.default_role === "provider") {
    rootDashboard = "/provider/dashboard";
  } else if (props.claims.default_role === "employer") {
    rootDashboard = "/employer/dashboard";
  } else if (props.claims.default_role === "scheduler") {
    rootDashboard = "/scheduler/orders";
  } else if (props.claims.default_role === "broker") {
    rootDashboard = "/employers";
  }

  return (
    <>
      <Sidenav
        orientation={props.orientation}
        className={layoutSidenavClasses()}
      >
        {/* Brand demo (see src/demo.css) */}
        {props.orientation !== "horizontal" && (
          <React.Fragment>
            {/**  Header */}
            <HeaderSidenav
              clearAllFilters={clearAllFilters}
              toggleSidenav={toggleSidenav}
              rootDashboard={rootDashboard}
            />
            <Sidenav.Divider className="mt-0" />
          </React.Fragment>
        )}

        <ScrollArea>
          <div
            className={`sidenav-inner ${
              props.orientation !== "horizontal" ? "py-1" : ""
            }`}
          >
            {/**  Agent Tools */}
            {props.claims.default_role === "agent" && (
              <AgentToolsMenu
                claims={props.claims}
                permissions={permissions}
                clearAllFilters={clearAllFilters}
                isMenuActive={isMenuActive}
                isMenuOpen={isMenuOpen}
              />
            )}
            {/**  Provider Tools */}
            {props.claims.default_role === "groupadmin" && (
              <ProviderToolsMenu
                permissions={permissions}
                clearAllFilters={clearAllFilters}
                isMenuActive={isMenuActive}
                isMenuOpen={isMenuOpen}
              />
            )}
            {/**  Employer Tools */}
            {props.claims.default_role === "employer" && (
              <EmployerToolsMenu
                clearAllFilters={clearAllFilters}
                permissions={permissions}
                isMenuActive={isMenuActive}
                isMenuOpen={isMenuOpen}
              />
            )}
            {/**  Broker Tools */}
            {isBroker && (
              <PartnerToolsMenu
                clearAllFilters={clearAllFilters}
                brokerUsesFMHProviderMarketplace={
                  brokerUsesFMHProviderMarketplace
                }
                isMenuActive={isMenuActive}
                isMenuOpen={isMenuOpen}
              />
            )}

            <Sidenav.Divider className="mb-1" />
            <AdditionalToolsMenu clearAllFilters={clearAllFilters} />
          </div>
        </ScrollArea>
      </Sidenav>
    </>
  );
}

const QUERY = gql`
  query GetBrokerUsesFMHProviderMarketplace($broker_user_id: Int!) {
    user(
      where: {
        _and: [
          { id: { _eq: $broker_user_id } }
          { broker_uses_fmh_provider_marketplace: { _eq: true } }
        ]
      }
    ) {
      id
    }
  }
`;
