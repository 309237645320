import React from "react";
import Sidenav from "../../../../vendor/libs/sidenav";
import { NavMenuItemLink } from "../navigation-menu-items/NavMenuItemLink";

export default function PartnerToolsMenu(props) {
  const menutItems = ["employers"];

  if (props.brokerUsesFMHProviderMarketplace) {
    menutItems.push("providers");
  }

  return (
    <>
      <Sidenav.Header className="small font-weight-semibold">
        PARTNER TOOLS
      </Sidenav.Header>

      {menutItems.map((menuItem) => {
        return (
          <NavMenuItemLink
            key={menuItem}
            name={menuItem}
            clearAllFilters={props.clearAllFilters}
          />
        );
      })}
    </>
  );
}
